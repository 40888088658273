import styles from './css-modules/collab_projects.module.css';
import { useEffect } from 'react';

function CollabProjects({ projects }) {
    return (
        <div className={'section'}>
            <h2>Collaborative Projects</h2>
            <div className={styles.flexed_projects}>
                {projects.length > 0 && projects.map((project, index) => {
                    return <div key={'project_' + index} className={styles.container}>
                        <img src={project.projectInformation.image.sourceUrl} className={styles.title_image}></img>
                        <div className={styles.content_container}>
                            <h3 className={styles.title}>{project.title}</h3>
                            <p className={styles.dob}>{project.projectInformation.dateOfPublication}</p>
                            {/* <div className={styles.description} dangerouslySetInnerHTML={{__html: project.projectInformation.description}}></div> */}
                            {project.categories.nodes.length > 0 && <p className={styles.sectors}>Sector: {project.categories.nodes.map((cat, index) => {
                                return <span key={'categ' + index}>{cat.name} {index < project.categories.length - 1 ? <>,</> : <></>}</span>
                            })}</p>}
                            <a href={project.project_link.link} target="_blank" className={'button'}>
                                <div className={'button_animator'}>
                                    <div className={'button_animator_inner'}></div>
                                </div>
                                <p className={'button_text'}>Read More</p>
                            </a>
                        </div>
                    </div>
                })}
            </div>
        </div>
    );
}

export default CollabProjects;